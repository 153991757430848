(function ($) {
  window.site = site || {};
  site.drawers = site.drawers || {};

  site.drawers.$formatters = $();
  site.drawers.$closeButtons = $();

  site.drawers.animationSpeed = 300;
  site.drawers.mouseOutDelay = 800;
  site.drawers.drawerOpen = false;
  site.drawers.waited = false;
  site.drawers.waiting = false;
  site.drawers.isOver = false;
  site.drawers.keepOpen = false;

  var initialized = false;

  // Drawers functionality
  site.drawers.init = function (context) {
    // Only run once
    if (initialized) {
      return;
    }
    initialized = true;

    var $footer = $('.site-footer', context);
    var $stickyFooter = $('.site-footer--sticky', context);
    var offset = 0;
    var stickyFooterHeight = $stickyFooter.height();

    if (!$footer.length) {
      return;
    }

    function _setOffset() {
      offset = $footer.offset().top - $(window).height() + stickyFooterHeight;
      $stickyFooter.toggleClass('is-sticky', $(window).scrollTop() < offset);
    }

    var footerSetOffSet = _.debounce(_setOffset, 300);

    $(window).scroll(footerSetOffSet).resize(footerSetOffSet);

    _setOffset();
    // Use a timeout to ensure this is run after other scripts set their dimensions
    setTimeout(_setOffset, 60);

    site.drawers.$formatters = $('.drawer-formatter.js-mac-select-link', context);
    site.drawers.$container = $('.drawer-container', context);
    site.drawers.$closeButtons = $('.drawer-formatter__close', site.drawers.$container);

    function _mouseMove(event, over) {
      site.drawers.isOver = over === true ? true : false;
      // Don't bother with any of this if the drawer is closed
      if (!site.drawers.drawerOpen || site.drawers.keepOpen) {
        return;
      }

      if (event) {
        site.drawers.isOver = !!$(event.target).closest('.drawer-container').length;
      }

      if (!site.drawers.waiting && !site.drawers.waited) {
        // Set a delay before we check the cursor again
        site.drawers.waiting = true;
        setTimeout(function () {
          site.drawers.waited = true;
          site.drawers.waiting = false;
          _mouseMove(false, site.drawers.isOver);
        }, site.drawers.mouseOutDelay);
      } else if (site.drawers.waited) {
        if (!site.drawers.isOver) { // If we're still not over the container, close it
          _close();
        }
        site.drawers.waited = false;
      }
    }

    function _close(event) {
      if (typeof event != 'undefined') {
        event.preventDefault();
      }
      site.drawers.close();
      $('.js-sticky-footer').attr('tabindex', '-1');
    }

    site.drawers.$formatters.each(function () {
      var $formatter = $(this);
      var $pane = $('.drawer-formatter__content', site.drawers.$container).filter(function () {
        return !($(this).css('visibility') === 'hidden' || $(this).css('display') === 'none' || $(this).css('opacity') === 0);
      });
      var $trigger = $('.js-sticky-footer-pc-links', $formatter);
      var hidePane = $pane.is(':hidden');
      var paneHeight = $pane.show().height();

      $pane.data('paneHeight', paneHeight);

      function _mouseOver(event) {
        var $macStickyFooter = $('.js-sticky-footer');

        event.preventDefault();
        var speed = site.drawers.drawerOpen ? 0 : site.drawers.animationSpeed;

        site.drawers.open($(this), $pane, speed);
        $macStickyFooter.attr('tabindex', '0').focus();
        site.restrict_navigation($macStickyFooter);
      }

      $trigger.on('mouseover click', _mouseOver);

      $trigger.on('focusout', function () {
        $('.js-sticky-footer-pc-links.custom-outline').removeClass('custom-outline');
      });
      if (hidePane) {
        $pane.hide();
      } else {
        site.drawers.drawerOpen = true;
      }
    });

    $('body', context).on('mousemove', _mouseMove);
    site.drawers.$closeButtons.on('click', _close);
    $('.js-sticky-footer-slider-icon').on('keydown', function (e) {
      if (site.getKeycode(e) === 13) {
        e.preventDefault();
        $('.js-sticky-footer-pc-links.active').focus().addClass('custom-outline');
        _close();
      }
    });
  };

  // Drawers Open
  site.drawers.open = function ($trigger, $pane, speed, keepOpen) {
    if (site.drawers.drawerOpen) {
      $pane.siblings('.drawer-formatter__content').hide().css('top', 0);
    }
    site.drawers.drawerOpen = true;
    $pane.stop().show().data('paneHeight', $pane.height() + $trigger.height() + 20);
    $pane.stop().show().animate({
      top: -$pane.data('paneHeight')
    }, speed);
    $('.js-sticky-footer-pc-links', site.drawers.$container).removeClass('active');
    $trigger.addClass('active');
    this.keepOpen = !!keepOpen;
    site.drawers.lastPaneOpen = $pane;
    site.drawers.lastSpeed = speed;
  };

  // Drawers Close
  site.drawers.close = function ($pane) {
    if (typeof $pane == 'undefined') {
      $pane = $('.drawer-formatter__content:visible');
    }
    if (!$pane.is(':visible')) {
      return;
    }
    site.drawers.drawerOpen = false;
    site.drawers.keepOpen = false;
    $pane.stop().animate({
      top: 0
    }, site.drawers.animationSpeed, function () {
      $pane.hide();
    });
    $('.js-sticky-footer-pc-links').removeClass('active');
  };

  /**
   * Generic behaviors for footer drawers
   */
  Drupal.behaviors.stickyFooterV1 = {
    attach: function (context) {
      // Live chat Trigger
      $('.js-mac-live-chat').bind('click', function (e) {
        e.preventDefault();
        $('#product-page-livechat-hitarea div').click();
        $('div', '#LP_DIV_MAC_MOBILE_FOOTER').click();
      });

      $('.mac-select-chat').bind('click', function (e) {
        e.preventDefault();
        if ($('span.footer-liveperson-link').length > 0) {
          $('span.footer-liveperson-link')[0].click();
        }
      });

      var mobileLinks = $('.sticky-footer-links-formatter .disable-pc:visible').length;
      var disableLargeElements = $('.sticky-footer-links-formatter .disable-pc');
      var disableSmallElements = $('.sticky-footer-links-formatter .disable-mobile');

      disableLargeElements.addClass('col-md-' + mobileLinks).first().addClass('no-bar');
      disableSmallElements.first().addClass('no-bar');
      disableSmallElements.last().addClass('no-bar');
      site.drawers.init(context);
    }
  };
})(jQuery);
